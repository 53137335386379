import React from 'react';

import { Whatsapp as WhatsAppIcon } from '@styled-icons/boxicons-logos/Whatsapp';
import { Mail as MailIcon } from '@styled-icons/fluentui-system-filled/Mail';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Flex,
  Grid,
  HStack,
  Heading,
  Box,
  Icon,
  Link,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

const ModalSupport: React.FC<ModalRootProps> = ({
  handleClose,
  ...restProps
}) => {
  return (
    <Modal {...restProps} size="md" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Suporte ecustos</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Heading as="h3" size="md" marginBottom={4}>
            Fale com a gente
          </Heading>

          <Flex
            w="100%"
            as="form"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            sx={{
              '> *': {
                marginY: 1,
              },
            }}
          >
            <Grid
              w="100%"
              templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
              columnGap={2}
              rowGap={4}
            >
              <Box d="flex" alignItems="center" gap={2}>
                <Icon as={WhatsAppIcon} boxSize={6} />
                <Link
                  href="https://api.whatsapp.com/send?phone=554830278104"
                  target="_blank"
                  rel="noreferrer"
                  color="blue.500"
                >
                  +55 (48) 3027-8104
                </Link>
              </Box>

              <Box d="flex" alignItems="center" gap={2}>
                <Icon as={MailIcon} boxSize={6} />
                <Link
                  href="mailto:suporte@ecustos.com.br"
                  target="_blank"
                  rel="noreferrer"
                  color="blue.500"
                >
                  suporte@ecustos.com.br
                </Link>
              </Box>
            </Grid>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button onClick={handleClose}>Fechar</Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalSupport;
